import React from "react";

const PageNotFound = () => {
    return(
        <div>
            <h1>404</h1>
            <p>PAGE NOT FOUND</p>
            <a href="/home">BACK TO HOMEPAGE</a>
        </div>
    )
}
export default PageNotFound;